<template>
  <div class="eg-container my-5" v-if='!loading && sectionData'>
    <div class="container" v-if="$route.name == 'Home'">
      <div
        class="eg-content"
        :class="ar ? 'eg-content' : 'eg-content-ar'"
        :style="{ backgroundImage: `url(${overlay}),  url(${sectionData.media_url || bg})` }"
      >
        <h3
          class="header-main-white d-flex align-items-center my-5"
          :class="fl_ar_rev"
        >
          {{sectionData.titles[lang] || $t('About egypt')}}
          <span class="header-divider d-inline-block mx-1 mt-1"></span>
        </h3>
        <h2 v-if='sectionData.descriptions[lang]' class="eg-text" :class="t_ar" v-html='sectionData.descriptions[lang]'>
        </h2>
        <h2 v-else class="eg-text" :class="t_ar">
          هي دولة عربية تقع في الركن الشمالي الشرقي من قارة أفريقيا، ولديها
          امتداد آسيوي، حيث تقع شبه جزيرة سيناء داخل قارة آسيا فهي دولة عابرة
          للقارات، قُدّر عدد سكانها بـ104 ملايين نسمة، ليكون ترتيبها الثالثة عشر
          بين دول العالم بعدد السكان والأكثر سكانًا عربيًّا. ولجمهورية مِصرَ
          العربية سواحل طويلة على البحرين الأبيض والأحمر، وتشترك بحدود مع سبعة
          دول، تبلغ مساحة جمهورية مصر العربية حوالي 1.000.002 كيلومتر مربع.
          والمساحة المأهولة تبلغ 78990
        </h2>
        <button type="button" :class="['btn-pri', dir_ar == 'dir-rtl'? 'ms-auto':'me-auto']" @click="goToAboutEgypt" >
          {{$t('read more')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AboutEgypt from '../views/AboutEgypt.vue';

export default {
  components: { AboutEgypt },
  data() {
    return {
      bg: require("@/assets/images/about.png"),
      overlay: require("@/assets/images/overrec.png"),
      loading: true,
      sectionData: null
    };
  },
  props: ['section'],
  mounted() {
    this.loadVSection({
      id: this.section.id
    }).then(res => {
      this.sectionData = res.data.results
      this.loading = false;
    })
  },  
  methods: {
    ...mapActions(['loadVSection']),
    goToAboutEgypt() {
      this.$router.push({ name: "About Egypt" });
    },
  },
  computed: {
    ...mapGetters(['getVSection'])
  }
};
</script>