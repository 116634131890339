var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.sectionData)?_c('div',{staticClass:"about-eg-embassy-page relative-sec"},[(_vm.$route.name != 'Home')?_c('Page-title',{attrs:{"title":_vm.sectionData.titles[_vm.lang] || _vm.$t('about embassy'),"desc":_vm.$t('default description')}}):_vm._e(),_c('div',{staticClass:"container my-5"},[_c('div',{class:['row', 'mt-5', 'mb-5', _vm.fl_ar]},[_c('div',{class:[_vm.$route.name == 'Home' ? 'col-md-6' : 'col-12']},[_c('div',{class:[
            _vm.$route.name == 'Home' ? 'intro-img' : 'w-100 intro-img-in',
            _vm.dir_ar == 'dir-rtl' ? 'me-auto' : 'ms-auto' ],style:({
            backgroundImage: ("url(" + (_vm.sectionData.media_url || _vm.introImg) + ")"),
          })})]),_c('div',{class:[_vm.$route.name == 'Home' ? 'col-md-6' : 'col-12 mt-5']},[(_vm.$route.name == 'Home')?_c('h3',{staticClass:"header-main d-flex align-items-center my-5",class:_vm.fl_ar_rev},[_vm._v(" "+_vm._s(_vm.sectionData.titles[_vm.lang] || _vm.$t("about embassy"))+" "),_c('span',{staticClass:"header-divider d-inline-block mx-1 mt-1"})]):_vm._e(),(_vm.sectionData.descriptions[_vm.lang])?_c('div',{class:['artical', _vm.dir_ar]},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.sectionData.descriptions[_vm.lang])}}),(_vm.$route.name == 'Home')?_c('button',{staticClass:"btn-pri",on:{"click":function($event){return _vm.$router.push({
                name: 'About Embassy',
                params: { page_id: _vm.sectionData.page.id },
              })}}},[_vm._v(" "+_vm._s(_vm.$t("read more"))+" ")]):_vm._e()]):_vm._e()])])])],1):(_vm.loading)?_c('div',{staticClass:"container my-5 py-5"},[_c('div',{staticClass:"loader my-5 mx-auto"})]):(_vm.err && !_vm.loading)?_c('div',{staticClass:"container py-5 my-5"},[_c('div',{staticClass:"err text-danger text-center"},[_c('i',{staticClass:"fa fa-exclamation-triangle"}),_vm._v(" "+_vm._s(_vm.err)+" ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }