<template>
  <div>
    <div class="main-section" v-if='!loading'>
      <div
        class="main-slider-container container"
      >
        <Swiper
          ref="newsSwiperSlider"
          class="swiper col-12 py-1 h-100"
          :options="swiperOptions"
          :dir="ar ? 'rtl' : 'ltr'"
          v-if="swiperStart"
        >
          <SwiperSlide class="" v-for="card in getVSlider.banners" :key="card.id">
            <div
              class="slider-card"
              :style="`background-image:url(${card.image_url})`"
            >
              <div class="overlay"></div>
              <div :class="['slider-card-text', 'text-center', dir_ar == 'dir-rtl'? 'text-md-end':'text-md-start']" >
                <h5>
                  {{ card.title }}
                </h5>
                <p>{{ card.description }}</p>
                <button
                  class="btn-pri"
                  v-if="card.target"
                  @click="readTheNews(card.target)"
                >
                  {{$t('read more')}}
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script>
import newsSwiper from "@/components/newsSwiper";
import Vue from 'vue'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    newsSwiper,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperStart: false,
      swiperOptions: {
        speed: 800,
        slidesPerView: 1,
        spaceBetween: 10,
        grabCursor: true,
        loop: true,
        autoplay:{
          delay: 3000
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
        },
      },
      swiper: null,
      loading: false,
    };
  },
  watch: {
    getVSlider: function() {
      // console.log(this.loading, 'loading')
      // let swiperInterval = setInterval(() => {
      //   if (this.$refs.newsSwiperSlider && this.$refs.newsSwiperSlider.$swiper) {
      //     // console.log(this.swiper, this.loading)
      //     this.swiper = this.$refs.newsSwiperSlider.$swiper;
      //     setInterval(() => {
      //       if(this.swiper ) {
      //         this.swiper.slideNext(300);
      //       }
      //     }, 7500);
      //     window.clearInterval(swiperInterval);
      //   }
      // }, 300)
    }
  },
  props: ['section'],
  mounted() {
    this.loading = true;
    this.loadVSlider({
      id: this.section.custom_fields.slider_id,
    }).then(res => {
      this.loading = false;
      setTimeout(() => {
        // console.log(this.getVSlider.banners);
        this.swiperStart = true
      }, 100);
    }).catch(err => {})
    // this.loadSliders().then((res) => {
    //   if (res.status == 200) {
    //     this.loadBanners().then((res) => {
    //       if (res.status == 200) {
    //         const mainSlider = this.getSliders.find(
    //           (slide) => slide.key == "mainSlider"
    //         );
    //         this.sliderStatus = mainSlider.status;
    //         const banners = this.getBanners.filter(
    //           (banner) => banner.slider_id == mainSlider.id
    //         );
    //         this.mainSliderSlides = banners;
    //       }
    //     });
    //   }
    // });
  },
  methods: {
    ...mapActions(["loadSliders", "loadBanners", 'loadVSlider']),
    readTheNews(newsId) {
      this.$router.push({
        name: "News-Artical",
        params: { id: newsId.target },
      });
    },

    goTo(link) {
      if (link != undefined) {
        window.open(link.target);
      }
    },
  },
  computed: {
    ...mapGetters(["getBanners", "getVSlider"]),
    // mainSwiper() {
    //   return this.$refs.newsSwiperSlider.$swiper;
    // },
  },
};
</script>