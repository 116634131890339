import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import storage from './Helpers/storage';

Vue.use(VueI18n)

// Vue.mixin(storage); 
// console.log(this.storageGet('u_sj'))

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
let i18nObject = {
  locale: 'ar',
  fallbackLocale: 'ar',
  messages: loadLocaleMessages()
};

export default new VueI18n(i18nObject)
