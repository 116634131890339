<template>
  <div class="dynamic-page-container" v-if="!loading">
    <div class="container py-5 my-5" v-if="page.sections_count == 0">
      <div class="no-sections text-center py-5 my-5">
        <h3>
          {{ $t("noSections") }}
        </h3>
        <p class="desc">
          {{ $t("noSectionsDesc") }}
        </p>
      </div>
    </div>
    <div v-for="section in page.sections" :key="section.id">
      <section
        class="hero-section-container"
        v-if="section && section.style.type == 'mainSlider'"
      >
        <HeroSection :section="section" />
      </section>

      <!-- <section class="services-section-container">
        <Services />
      </section> -->

      <section
        class="services-section-container"
        v-else-if="section && section.style.type == 'secSlider'"
      >
        <div class="hero-container mt-4" :class="[t_ar]">
          <div class="news-holder">
            <div class="container">
              <newsSwiper :section="section" />
            </div>
          </div>
        </div>
      </section>

      <section
        class="trending-section-container app-sizing"
        v-else-if="section && section.style.type == 'mostUsedServices'"
      >
        <TrendingServices :section="section" />
      </section>

      <section
        class="aboutEmbassy-section-container app-sizing"
        v-else-if="section && section.style.type == 'aboutEmbassy'"
      >
        <AboutEmbassy :section="section" />
      </section>

      <section
        class="aboutEG-section-container app-sizing"
        v-else-if="section && section.style.type == 'aboutEgypt'"
      >
        <AboutEG :section="section" />
      </section>
      <section
        class="news-section-container app-sizing"
        v-else-if="section && section.style.type == 'latestNews'"
      >
        <LastNewsSection
          :title="$t('latest News')"
          :readMoreBtn="true"
          :section="section"
        />
      </section>

      <section
        class="contactUs-section-container"
        v-else-if="section && section.style.type == 'contactUs'"
      >
        <ContactUs :useTitle="true" :section="section" />
      </section>

      <section
        class="contactUs-section-container"
        v-else-if="section && section.style.type == 'subscribe'"
      >
        <NewsLetter :useTitle="true" :section="section" />
      </section>

      <section
        class="contactUs-section-container minHeight-abouteg"
        v-else-if="section && section.style.type == 'embassyHistory'"
      >
        <div class="about-eg-embassy-page container">
          <EmbassyHistory :section="section" />
        </div>
      </section>

      <section
        class="about-eg-embassy-page"
        v-else-if="section && section.style.type == 'mainAbout'"
      >
        <AbouteEgypt :useTitle="true" :section="section" />
      </section>

      <section
        class="services-page"
        v-else-if="section && section.style.type == 'allServices'"
      >
        <Services :useTitle="true" :section="section" />
      </section>

      <section class="h-100 justify-content-center" v-else>
        <div class="container">
          <h3 class="text-center">{{ section.title }}</h3>
          <div class="intro-image my-2" v-if="section.media_url">
            <img
              style="max-height: 500px; object-fit: cover"
              :src="section.media_url"
              width="100%"
              class="rounded"
              alt="custom section cover"
            />
          </div>
          <div :class="dir_ar" v-html="section.description"></div>
        </div>
      </section>
    </div>
  </div>
  <div class="container py-5 my-5" v-else>
    <div class="loader my-5 mx-auto"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import AboutEG from "../components/AboutEG.vue";
import HeroSection from "../components/HeroSection.vue";
import LastNews from "../components/LastNews.vue";
import NewsSwiper from "../components/newsSwiper.vue";
import ServiceData from "../components/ServiceData.vue";
import TrendingServices from "../components/TrendingServices.vue";
import AboutEgEmbassy from "./AboutEgEmbassy.vue";
import AboutEmbassy from "./AboutEgEmbassy.vue";
import NewsLetter from "../components/misc/NewsLetter.vue";
import LastNewsSection from "../views/LastNews.vue";
import EmbassyHistory from "../components/EmbassyHistory.vue";
import ContactUs from "../views/ContactUs.vue";
import Services from "../views/Services.vue";
import helpers from "../mixins/helpers";
import AbouteEgypt from "../views/AboutEgypt.vue";

export default {
  components: {
    AbouteEgypt,
    AboutEG,
    NewsSwiper,
    AboutEmbassy,
    AboutEgEmbassy,
    TrendingServices,
    Services,
    HeroSection,
    LastNews,
    ServiceData,
    NewsLetter,
    LastNewsSection,
    EmbassyHistory,
    ContactUs,
  },
  watch: {
    $route: function () {
      this.loading = false;
      this.loadVPage({ id: this.$route.meta.page_id }).then((res) => {
        helpers.sortItems(this.page.sections);
        this.loading = false;
      });
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.loadVPage({ id: this.$route.meta.page_id }).then((res) => {
      setTimeout(() => {
        helpers.sortItems(this.page.sections);
        this.loading = false;
      }, 250);
    });
  },

  methods: {
    ...mapActions(["loadVPage"]),
  },
  computed: {
    ...mapGetters(["controlledRoutes", "page"]),
  },
};
</script>

{id: 11, title: 'Main Slider', value: 'mainSlider'},
        {id: 5, title: 'Secondary Slider', value: 'secSlider'},
        {id: 3, title: 'Most Used Services', value: 'mostUsedServices'},
        {id: 4, title: 'About Embassy', value: 'aboutEmbassy'},
        {id: 7, title: 'About Egypt', value: 'aboutEgypt'},
        {id: 8, title: 'Latest News', value: 'latestNews'},
        {id: 9, title: 'Contact Us', value: 'contactUs'},
        {id: 10, title: 'Subscribe', value: 'subscribe'},
        {id: 1, title: 'Main About Section', value: 'mainAbout'},
        {id: 1, title: 'Embassy History', value: 'embassyHistory'},
        {id: 6, title: 'Custom', value: 'custom'},