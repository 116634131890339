<template>
  <div class="about-egypt-page" v-if="dataLoaded && sectionData">
    <PageTitle
      :title="sectionData.titles ? sectionData.titles[lang] : $t('About egypt')"
      :desc="$t('default description')"
    />
    <div class="container">
      <div
        class="intro-img-in"
        :style="{
          backgroundImage: `url(${
            sectionData.media_url ? sectionData.media_url : introImg
          })`,
        }"
      ></div>
      <div
        :class="['artical', dir_ar, 'my-5']"
        v-html="sectionData.descriptions[lang]"
      ></div>
      <about-eg-tabel :egInfo="egInfo" />
    </div>
  </div>
  <section-loader v-else />
</template>

<script>
import { mapActions } from "vuex";
import AboutEgTabel from "../components/AboutEgTabel.vue";
import PageTitle from "../components/misc/PageTitle.vue";
import SectionLoader from "../components/misc/SectionLoader.vue";
export default {
  components: {
    PageTitle,
    SectionLoader,
    AboutEgTabel,
  },
  props: ["section"],
  data() {
    return {
      introImg: require("../assets/images/news-cover-3.png"),
      egInfo: [
        {
          title: this.$t("name"),
          value: this.$t("egRepublic"),
        },
        {
          title: this.$t("capital"),
          value: this.$t("capitalDesc"),
        },
        {
          title: this.$t("othermajorcities"),
          value: this.$t("othermajorcitiesDesc"),
        },
        {
          title: this.$t("population"),
          value: this.$t("populationDesc"),
        },
        {
          title: this.$t("currency"),
          value: this.$t("currencyDesc"),
        },
      ],
      dataLoaded: false,
      sectionData: "",
    };
  },
  mounted() {
    this.loadVSection(this.section)
      .then((res) => {
        this.sectionData = res.data.results;
        this.dataLoaded = true;
      })
      .catch((err) => {
        console.log(err);
        this.dataLoaded = true;
      });
  },
  methods: {
    ...mapActions(["loadVSection"]),
  },
};
</script>

<style>
</style>