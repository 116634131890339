<template>
  <div
    class="news-container d-flex justify-content-center"
    v-if="!loading && getNews && getNews.length != 0"
  >
    <div class="col-12">
      <div class="row">
        <Swiper
          ref="mySwiper"
          class="swiper col-12 p-1 py-3"
          :options="swiperOptions"
          :dir="ar ? 'rtl' : 'ltr'"
        >
          <SwiperSlide class="col" v-for="card in getNews" :key="card.id">
            <div
              :class="card.image_url ? 'news-card' : 'news-card-placeholder'"
              @click="readTheNews(card)"
              :style="`background-image:url(${card.image_url || placeholder})`"
            >
              <div class="overlay"></div>
              <h5 class="news-card-text">
                {{ card.title }}
              </h5>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
  <section-loader v-else />
</template>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
} from "swiper/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
SwiperClass.use([Pagination, Mousewheel, Autoplay]);
Vue.use(getAwesomeSwiper(SwiperClass));
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
import "swiper/swiper-bundle.css";
import SectionLoader from "./misc/SectionLoader.vue";
export default {
  components: {
    Swiper,
    SwiperSlide,
    SectionLoader,
  },
  data() {
    return {
      // newsSliderSLides: null,
      // sliderStatus: false,
      swiper: null,
      loading: true,
    };
  },
  watch: {
    // getNews: function() {
    //   let swiperInterval = setInterval(() => {
    //     if(this.$refs.mySwiper && this.$refs.mySwiper.$swiper) {
    //       this.swiper = this.$refs.mySwiper.$swiper;
    //       // setInterval(() => {
    //       //   this.swiper.slideNext(300);
    //       // }, 2000);
    //       window.clearInterval(swiperInterval);
    //     }
    //   }, 300)
    // }
  },
  mounted() {
    this.loadNews({}).then((res) => {
      this.loading = false;
    });

    // this.loadNews().then((res) => {
    //   if (res.status == 200) {
    //     this.loadBanners().then((res) => {
    //       if (res.status == 200) {
    //         const newsSlider = this.getSliders.find(
    //           (slide) => slide.key == "newsSwiper"
    //         );
    //         this.sliderStatus = newsSlider.status;
    //         const banners = this.getBanners.filter(
    //           (banner) => banner.slider_id == newsSlider.id
    //         );
    //         this.newsSliderSLides = banners;
    //       }
    //     });
    //   }
    // });
  },
  methods: {
    ...mapActions(["loadSliders", "loadNews"]),
    slideNext() {
      this.swiper.slideNext(300);
    },
    slidePrev() {
      this.swiper.slidePrev(300);
    },
    readTheNews(news) {
      this.$router.push({
        name: "News-Artical",
        params: { id: parseInt(news.id) },
      });
    },
    // goTo(link) {
    //   console.log(link);
    //   if (link) {
    //     window.open(link.target);
    //   }
    // },
  },
  computed: {
    ...mapGetters(["newsList", "getSliders", "getNews"]),
    // swiper() {
    //   return this.$refs.mySwiper.$swiper;
    // },
    swiperOptions() {
      if (this.innerWidth >= 992) {
        return {
          autoplay: {
            delay: 3000,
          },
          speed: 800,
          slidesPerView: 3,
          spaceBetween: 0,
          loop: true,
          grabCursor: true,
          centeredSlides: false,
        };
      } else if (this.innerWidth >= 768) {
        return {
          speed: 800,
          slidesPerView: "auto",
          spaceBetween: 1,
          loop: true,
          grabCursor: true,
          centeredSlides: false,
          autoplay: {
            delay: 3000,
          },
        };
      } else {
        return {
          speed: 800,
          slidesPerView: "auto",
          spaceBetween: 10,
          loop: true,
          grabCursor: true,
          autoplay: {
            delay: 3000,
          },
          breakpoints: {
            992: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 1,
            },
          },
        };
      }
    },
  },
};
</script>