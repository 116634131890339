  <template>
  <div class="embassy-container" v-if='!loading && sectionData'>
    <div :class="['container', dir_ar]">
      <div class="row justify-content-around">
        <div class="col-5 d-none d-md-block sideImg">
          <img src="@/assets/images/side.png" width="100%" height="100%" />
        </div>
        <div class="col-md-7 col-12">
          <h3
            class="header-main d-flex align-items-center my-5"
            :class="fl_ar_rev"
          >
              {{sectionData.titles ? sectionData.titles[lang] :  $t('about embassy')}}
            <span class="header-divider d-inline-block mx-1 mt-1"></span>
          </h3>
          <p
            v-if="sectionData.descriptions"
            v-html='sectionData.descriptions[lang]'
            class="para"
          ></p>
          <div v-else>
            <p class="para" :class="t_ar">
              {{ para1 }}
            </p>
            <p class="para" :class="t_ar">
              {{ para2 }}
            </p>
          </div>

          <div class="mt-8" :class="t_ar">
            <button type="button" class="btn-pri" @click="goToAboutEgEmbassy">
              {{$t('read more')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      para1:
        "هي التمثيل الرسمي الدبلوماسي لجمهورية مصر العربية في جمهورية ألمانيا الاتحادية، وتقع في 6-7 شارع شتاوفنبرج في حي تيرجارتن في برلين. ويشغل بدر عبد العاطي منصب .السفير المصري في برلين وقد سبقه في المنصب السفير عبد الحميد إبراهيم حجازي",
      para2:
        "نشأت العلاقات الدبلوماسية بين كل من الرايخ الألماني والمملكة المصرية القديمة منذ عام 1925 ،حيث كان مقر السفير في ثمانية شارع ديلبروك منطقة جرونفالد، وفي عام 1929 تم الانتقال إلى شارع تيرجارتن. وكان سيف االله يسري باشا هو القنصل العام والمبعوث والوزير المكلف في عام 1930 .في عقد الأربعينيات اشترت الدولة المصرية قطعة الأرض ومنطقة في شارع شتاوفنبيرج أينما تقام عليه السفارة منذ عام 1999 .وقد انقطعت العلاقات .الدبلوماسية بين البلدين إلى حد ما خلال الحرب العالمية الثانية والعقود اللاحقة",
      loading: true,
      sectionData: null
    };
  },
  props: ['section'],
  mounted() {

  },  
  methods: {
    ...mapActions(['loadVSection']),
    goToAboutEgEmbassy() {
      this.$router.push({ name: "AboutEmbassy"});
    },
  },
  computed: {
    ...mapGetters(['getVSection'])
  }
};
</script>