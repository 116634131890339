<template>
  <div :class="['last-news', dir_ar]">
    <div class="container">
      <div class="d-flex justify-content-between align-items-end" >
        <div class="d-flex align-items-center">
          <h4 class="fw-bold">{{ title }}
          <span class="header-divider d-inline-block mx-1 mt-1"></span>
          </h4>
        </div>
        <div class="more-btn" v-if="readMoreBtn">
          <button class="btn" @click="lastNewsPage">عرض الكل</button>
        </div>
      </div>
      <div class="news-cards my-5">
        <NewsCard
          v-for="(news, index) in lastNews"
          :key="index"
          :news="news"
          @readMore="readMore(news.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewsCard from "./misc/NewsCard";
export default {
  props: ["lastNews", "title", "readMoreBtn"],
  components: {
    NewsCard,
  },

  methods: {
    readMore(index) {
      // const newsSelected = this.newsList.find((news) => id === news.id);
      // alert(`selected ${newsSelected.id} - ${newsSelected.title}`);
      this.$router.push({ name: "News-Artical", params: { id: index } });
    },
    lastNewsPage() {
      this.$router.push({ name: "Last-News" });
    },
  },
};
</script>

<style>
</style>