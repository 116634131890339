import Axios from "./embassyInc";

export default {
    state: {
        news: null,
        viewPage: null,
        category: {},
        categories: []
    },
    mutations: {
        setNews(state, newsPayload) {
            state.news = newsPayload;
        },
        setVNews(state, vNewsPayload) {
            state.viewPage = vNewsPayload;
        },
        setCategory(state, payload) {
            state.category = payload;
        },
        setCategoriess(state, payload) {
            state.categories = payload;
        },
    },
    getters: {
        getNews(state) {
            return state.news;
        },
        getVNews(state) {
            return state.viewPage;
        },
        category: (state) => state.category,
        categories: (state) => state.categories,
    },
    actions: {
        loadNews({ commit }) {
            commit('setDataLoading', true);
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/news").then(res => {
                    if (res.data.results != null) {
                        commit("setNews", res.data.results.data);
                        commit('setDataLoading', false);
                        resolve(res);
                    } else {
                        commit('setDataLoading', false);
                        reject(res);
                    }
                }).catch(err => {
                    commit('setDataLoading', false);
                    reject(err);
                });
            });
        },
        loadVNews({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/news/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setVNews", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        getCategories({commit}, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/categories`).then(res => {
                    if (res.data.results != null) {
                        commit("setCategories", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        getCategory({commit}, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/categories/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setcategory", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        
    }
};