<template>
  <div class="box-info my-5 py-5 px-3" :class="dir_ar">
    <div class="row">
      <div class="col-md-6">
        <div class="row my-4" v-for="(info, index) in egInfo" :key="index">
          <div class="col-5 col-md-5">{{ info.title }}</div>
          <div class="col-8 col-md-6">{{ info.value }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row my-4" v-for="(info, index) in egInfo" :key="index">
          <div class="col-4 col-md-5">{{ info.title }}</div>
          <div class="col-8 col-md-6">{{ info.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["egInfo"],
};
</script>

<style>
</style>