<template>
  <div class="contact-us-page relative-sec">
    <PageTitle :title="$t('contactUs')" />
    <div class="container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.3891775628385!2d13.365572724729324!3d52.50749024608068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a851b41463f5b1%3A0xfab6a27f593ed8c3!2z2LPZgdin2LHYqSDYrNmF2YfZiNix2YrYqSDZhdi12LEg2KfZhNi52LHYqNmK2Kk!5e0!3m2!1sar!2seg!4v1637324991645!5m2!1sar!2seg"
        width="100%"
        height="350"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        v-if="$route.name != 'Home'"
      ></iframe>
      <div :class="['row', 'mt-3', 'mb-5', dir_ar, fl_ar]">
        <div class="col-12 col-md-8 mb-4 mb-md-0">
          <ContactUs :useTitle="false" />
        </div>
        <div class="col-12 col-md-4">
          <div :class="['card-info', 'd-flex', 'flex-column', 'p-4', dir_ar]">
            <div
              class="row my-1 align-items-center"
              v-for="(info, index) in infoBox"
              :key="index"
            >
              <div class="col-2 p-0 align-items-center d-flex">
                <i :class="info.icon"></i>
              </div>
              <div class="col-10 p-0 align-items-start d-flex">
                <a
                  :href="info.link"
                  class="fw-bold m-0 no-link-style text-underline"
                  target="_blank"
                  :dir="index != 0 ? '' : 'ltr'"
                >
                  {{
                    index == 0
                      ? info.text.replace(/(\d{2})(\d{2})/, "$1 $2  ")
                      : info.text
                  }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/misc/PageTitle.vue";
import ContactUs from "../components/ContactUs.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PageTitle,
    ContactUs,
  },
  data() {
    return {
      infoBox: [
        {
          icon: "fa fa-phone",
          text: "+49304775470",
          link: "tel:+49304775470",
        },
        {
          icon: "fa fa-envelope",
          text: "support@egyption-embassy.de",
          link: "mailto:support@egyption-embassy.de",
        },
        {
          icon: "fa fa-map-marker",
          link: "https://goo.gl/maps/C3v8ZP8LMAuGBxPj9",
          text: "Stauffenbergstr. 6-7 10785 Berlin",
        },
      ],
    };
  },
  mounted() {
    if (this.getSettings) {
      this.infoBox[0].text = "+" + this.getSettings.phone;
      this.infoBox[0].link = `tel:+${this.getSettings.phone}`;
      this.infoBox[1].text = this.getSettings.email;
      this.infoBox[1].link = `mailto:${this.getSettings.email}`;
      this.infoBox[2].text = this.getSettings.address;
    }
  },
  computed: {
    ...mapGetters(["getSettings"]),
  },
};
</script>

<style>
</style>