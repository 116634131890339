<template>
  <section class="lastnews-page">
    <PageTitle
      :title="$t('latest News')"
      :desc="$t('default description')"
      v-if="$route.name != 'Home'"
    ></PageTitle>
    <div :class="['container', dir_ar]" v-if="loading">
               <h3 class="header-main d-flex align-items-center my-5"  v-if="$route.name == 'Home'">
          {{ $t('latest News')}}
        <span class="header-divider d-inline-block mx-1 mt-1"></span>
               <div :class="['more-btn', dir_ar == 'dir-rtl'?'me-auto':'ms-auto']">
          <button class="btn" @click="viweAll"> {{$t('show all') }}</button>
        </div>
      </h3>
      <div class="news mt-3">
        <NewsCard
          v-for="(news, index) in homeList"
          :key="index"
          :news="news"
          @readMore="readMore(news.id)"
        />
      </div>
    </div>
    <div class="container my-5" v-if="!loading">
    <div class="loader mx-auto my-5" ></div>
    </div>
  </section>
</template>

<script>
import PageTitle from "../components/misc/PageTitle.vue";
import NewsCard from "../components/misc/NewsCard.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    PageTitle,
    NewsCard,
  },
  data() {
    return {
      loading: false,

    }
  },
  props: ['section'],
  computed: {
    ...mapGetters(["newsList", 'getNews']),
       homeList(){
         if(this.getNews && this.$route.name == 'Home'){
           const list = [];
           list.push(this.getNews[0])
           list.push(this.getNews[1])
           list.push(this.getNews[2]);
           return list
         }else{
           return this.getNews
         }
    }
  },
  mounted() {
    this.loadNews({})
      .then(res => {
        this.loading = true;
      })
  },
  methods: {
    ...mapActions(['loadNews']),
    viweAll(){
      this.$router.push({name: 'Last-News'})
    },
    readMore(index) {
      this.$router.push({ name: "News-Artical", params: { id: index } });
    },
  },
};
</script>

<style>
</style>