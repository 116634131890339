<template>
  <div :class="['news-letter', lang]">
    <div :class="['container', dir_ar]">
      <div class="row align-items-center">
        <div :class="['col-md-5','d-flex align-items-center',  dir_ar == 'dir-rtl'? 'text-end':'text-start']">
          <h5>
            {{section && section.title? section.title:$t('subscribeDesc')}}
            <span class="header-divider d-inline-block mx-1 mt-1"></span>
          </h5
          >
        </div>
        <div class="col-md-7">
          <div class="text-center">
            <p v-if="subSuc">{{ subSuc }}</p>
            <p v-if="subErr">{{ subErr }}</p>
          </div>
          <form @submit.prevent="subAction" dir="ltr" :class="lang">
            <button class="btn">{{$t('subscribe')}}</button>
            <input
              type="email"
              :placeholder="$t('enterEmail')"
              v-model="subEmail"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  props:['section'],
  data() {
    return {
      subSuc: "",
      subErr: "",
      subEmail: ""
    }
  },
  methods: {
     ...mapActions(["subscribe"]),
    subAction() {
      this.subscribe({
        path: this.$route.path,
        email: this.subEmail,
      })
        .then((res) => {
          if (res.status == 200) {
            this.subErr = "";
            this.subSuc = this.$t('checkInbox');
            setTimeout(() => {
              this.subSuc = "";
            }, 5000);
          }
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.subSuc = "";
            this.subErr = this.$t('plzEnterMail');
            setTimeout(() => {
              this.subErr = "";
            }, 55000);
          }
        });
    },
  },

}
</script>