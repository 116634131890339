<template>
  <div :class="['card',dir_ar =='dir-rtl'? 'text-end':'text-start']">
    <img v-if='news.image_url' class="card-img-top" :src="news.image_url" alt="News Image" />
    <img v-else class="card-img-top-placeholder" :src="placeholder" alt="News Image" />
    <div :class="['card-body', dir_ar == 'dir-rtl'? 'text-end':'text-start']">

      <p class="date">
            {{ news.category.created_at.match(/(\d{4}-\d{2}-\d{1,2}).*/)[1]}}
      </p>
      <h4 class="card-title">{{ news.title }}</h4>
      <p class="card-text">{{ news.descriptions[lang].substring(0, 100) }}...</p>
      <button
        class="btn d-flex jsutify-content-around align-items-center "
        :class="[dir_ar == 'dir-rtl'?'ms-auto':'me-auto', fl_ar_rev]"
        @click="readMore"
      >
        <i
          v-if="dir_ar == 'dir-rtl'"
          class="fa fa-chevron-left"
        ></i>
        {{$t('read more')}}
        <i
          class="fa fa-chevron-right"
          v-if="dir_ar == 'dir-ltr'"
        ></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["news"],
  methods: {
    readMore() {
      this.$emit("readMore");
    },
  },
};
</script>

<style>
</style>