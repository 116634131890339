<template>
  <div
    class="about-eg-embassy-page relative-sec"
    v-if="!loading && sectionData"
  >
    <Page-title
      :title="sectionData.titles[lang] || $t('about embassy')"
      :desc="$t('default description')"
      v-if="$route.name != 'Home'"
    />
    <div class="container my-5">
      <div :class="['row', 'mt-5', 'mb-5', fl_ar]">
        <div :class="[$route.name == 'Home' ? 'col-md-6' : 'col-12']">
          <div
            :class="[
              $route.name == 'Home' ? 'intro-img' : 'w-100 intro-img-in',
              dir_ar == 'dir-rtl' ? 'me-auto' : 'ms-auto',
            ]"
            :style="{
              backgroundImage: `url(${sectionData.media_url || introImg})`,
            }"
          ></div>
        </div>
        <div :class="[$route.name == 'Home' ? 'col-md-6' : 'col-12 mt-5']">
          <h3
            class="header-main d-flex align-items-center my-5"
            :class="fl_ar_rev"
            v-if="$route.name == 'Home'"
          >
            {{ sectionData.titles[lang] || $t("about embassy") }}
            <span class="header-divider d-inline-block mx-1 mt-1"></span>
          </h3>
          <div
            :class="['artical', dir_ar]"
            v-if="sectionData.descriptions[lang]"
          >
            <p v-html="sectionData.descriptions[lang]"></p>
            <button
              class="btn-pri"
              v-if="$route.name == 'Home'"
              @click="
                $router.push({
                  name: 'About Embassy',
                  params: { page_id: sectionData.page.id },
                })
              "
            >
              {{ $t("read more") }}
            </button>
          </div>
        </div>
      </div>
      <!-- <EmbassyHistory class="about-eg-embassy-page" :section="section"/> -->
    </div>
  </div>
  <div class="container my-5 py-5" v-else-if="loading">
    <div class="loader my-5 mx-auto"></div>
  </div>
  <div class="container py-5 my-5" v-else-if="err && !loading">
    <div class="err text-danger text-center">
      <i class="fa fa-exclamation-triangle" /> {{ err }}
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/misc/PageTitle.vue";
import EmbassyHistory from "../components/EmbassyHistory.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    PageTitle,
    EmbassyHistory,
  },
  data() {
    return {
      introImg: require("../assets/images/news3.png"),
      articalImage: require("../assets/images/news2.png"),
      loading: true,
      sectionData: null,
      err: "",
    };
  },
  props: ["section"],
  created() {
    this.loadVSection({
      id: this.section.id,
    })
      .then((res) => {
        this.sectionData = res.data.results;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
        this.err = err.response.data.results.message;
      });
  },
  methods: {
    ...mapActions(["loadVSection", "loadVPage"]),
  },
  computed: {
    ...mapGetters(["getVSection"]),
  },
};
</script>

<style>
</style>