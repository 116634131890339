import axios from 'axios';
// import store from './news';
//  import router from '../router/index';
// import storage from './storage';
import i18n from '../i18n';
const embassyInc = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://test.optivius.com/embassy/public_html',
  // baseURL: process.env.VUE_APP_BASE_URL,
  // headers: {'Content-Language': i18n.locale}

});
embassyInc.interceptors.request.use(function (config) {
  // console.log(store)
  // let user = store.state.user;
  //   let userData = window.VueInc.storageGet('u_dk');
  // let token = user ? userData.token : null;
  // config.headers.Authorization = `Bearer ${userData.token}`
  config.headers['Content-Language'] = i18n.locale;
//   store.mutations.setAppLoading(store.state, true);
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

embassyInc.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
//   store.mutations.setAppLoading(store.state, false);
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
//   store.mutations.setAppLoading(store.state, false);
  // if (error.response.status == 401 && error.response.data.results.message == "Unauthorized.") {
  //   localStorage.removeItem('u_dk');
  //   localStorage.removeItem('up_dk');
  //   setTimeout(() => {
  //     router.push({name: 'Login'});
  //   }, 500);
  // }
  return Promise.reject(error);
});
// Vue.prototype.$http = axiosIns

export default embassyInc;
