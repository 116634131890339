<template>
  <div
    class="accordion service-data"
    id="faqAccordion"
    v-if="hint.titles[lang]"
  >
    <div class="accordion-item">
      <h2 class="accordion-header" :id="'headingOne-'">
        <button
          :class="['accordion-button', 'collapsed', dir_ar]"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#collapseOne-'"
          aria-expanded="false"
          :aria-controls="'collapseOne-'"
        >
          <!-- {{hint}} -->
          {{ hint.titles[lang] }}
        </button>
      </h2>
      <div
        :id="'collapseOne-'"
        class="accordion-collapse collapse hide"
        :aria-labelledby="'headingOne-'"
        data-bs-parent="#faqAccordion"
      >
        <div class="accordion-body" v-html="hint.descriptions[lang]"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["hint"],
};
</script>

<style>
</style>