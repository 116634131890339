<template>
  <div class="langChanger-container dropdown m-0">
    <button
      :class="['btn-nav-lang', padd ? '' : 'px-1',  dir_ar, 'm-0']"
      type="button"
      style="color: #000"
      id="accountDropdown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <!-- <i class="fa fa-chevron-down mx-2"></i> -->
      <img :src="currentLangImg()"  />
      <span v-if="lang == 'ar'" class="d-none d-md-inline-block"> العربيه </span>
      <span v-if="lang == 'en'" class="d-none d-md-inline-block"> English </span>
      <span v-if="lang == 'de'" class="d-none d-md-inline-block"> Deutsch </span>
    </button>
    <ul :class="['dropdown-menu', dir_ar]" aria-labelledby="dropdownMenuButton1">
      <li
        v-for="(availablelang, i) in availableLangs"
        :key="i"
        v-show="availablelang != lang"
        :class="dir_ar"
      >
        <a
          class="dropdown-item dropdown-link"
          :class="['d-flex',dir_ar == 'dir-rtl'? fl_ar_rev:'', dir_ar == 'dir-rtl'? 'justify-content-end':'justify-content-end']"
          @click="changeLang(availablelang)"
        >
          <span v-if="availablelang == 'ar'">العربيه</span>
          <span v-if="availablelang == 'en'">English</span>
          <span v-if="availablelang == 'de'">Deutsch</span>
          <img
            class="mx-2"
            :src="LangImg(availablelang, i)"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["padd"],
  data() {
    return {};
  },
  methods: {
    currentLangImg() {
      return require(`../../assets/images/flags/${this.lang}circle.png`);
    },
    LangImg(availablelang, i, needIndex) {
      if (needIndex) {
        return i;
      } else {
        return require(`../../assets/images/flags/${availablelang}circle.png`);
      }
    },
    changeLang(lang) {
      if (lang !== this.lang) {
        let newPref = lang;
        this.storageSet('up_ege', newPref)
        this.$router.go();
        // this.$i18n.locale = lang;
      }
    },
  },
  computed: {
    availableLangs() {
      return this.$i18n.availableLocales;
    },
  },
};
</script>
