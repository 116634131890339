<template>
  <section class="services-page mb-5 pb-5">
    <!-- {{lang}} -->
    <!-- {{servicesData}} -->
    <PageTitle
      :title="$t('allServices')"
      :desc="$t('default description')"
    ></PageTitle>
    <div
      class="container"
      v-if="!loading && servicesData.length != 0 && servicesData != null"
    >
      <div class="trending-container px-2">
        <div class="trending-cards row">
          <TrendingServiceCard
            v-for="(service, index) in servicesData"
            :card="service"
            @activeCard="activeCard(index)"
            :key="service.id"
            :elid="'serve-card-' + index"
            :isActive="truthArray[index] == true"
            :style="{
              backgroundImage: `url(${overlay}),  url(${
                service.img || service.image_url
              })`,
            }"
            @reservation="serviceReservation(service.id)"
          ></TrendingServiceCard>
        </div>
      </div>
    </div>
    <SectionLoader v-else />
  </section>
</template>

<script>
import PageTitle from "@/components/misc/PageTitle.vue";
import TrendingServiceCard from "@/components/misc/TrendingServiceCard.vue";
import { mapGetters, mapActions } from "vuex";
import SectionLoader from "../components/misc/SectionLoader.vue";
export default {
  components: { PageTitle, TrendingServiceCard, SectionLoader },
  computed: {
    ...mapGetters(["trendingCards"]),
  },
  data() {
    return {
      truthArray: [],
      overlay: require("@/assets/images/overlay.png"),
      loading: true,
      sectionData: null,
      servicesData: null,
    };
  },
  props: ["section"],
  mounted() {
    this.getServices({}).then((serv) => {
      this.servicesData = serv.data.results.data;
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(["loadVSection", "getServices"]),
    goToAboutEgypt() {
      this.$router.push({ name: "AboutEG" });
    },
    serviceReservation(serviceId) {
      this.$router.push({
        name: "serviceReservation",
        params: { id: serviceId },
      });
    },
    activeCard(index) {
      let otherels = [...document.getElementsByClassName("trending-card")];
      otherels.forEach((el) => {
        el.classList.remove("active");
        this.truthArray.push(false);
      });
      let ele = document.getElementById("serve-card-" + index);
      ele.classList.toggle("active");
      for (let i = 0; i < this.truthArray.length; i++) {
        if (i == index) {
          this.truthArray[i] = true;
        } else {
          this.truthArray[i] = false;
        }
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style>
</style>
desc="   هي التمثيل الرسمي الدبلوماسي لجمهورية مصر العربية في جمهورية ألمانيا
          الاتحادية،
          
          وتقع في 6-7 شارع شتاوفنبرج في حي تيرجارتن في برلين
