<template>
  <div
    class="trending-card col-lg-3 col-md-4 col-sm-6 col-12 my-10 mx-auto"
    @mouseover="activeCard"
    :id="elid"
  >
    <h5 class="trending-text">
      {{ card.title }}
    </h5>
    <transition name="slide">
      <button
        class="btn-sec w-100 mt-4 pointer"
        type="button"
        v-if="isActive"
        @click="serviceReservation"
      >
        {{$t('bookService')}}  
      </button>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["card", "isActive", 'elid'],
  methods: {
    activeCard() {
      this.$emit("activeCard");
    },
    serviceReservation() {
      this.$emit("reservation");
    },
  },
};
</script>