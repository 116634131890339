import Vue from 'vue';
import VueRouter from 'vue-router';
import /* webpackChunkName: "Home" */ Home from '../views/Home.vue';
import store from '../store/index'
import * as helpers from '../mixins/helpers';

Vue.use(VueRouter);

import DynamicPage from '../views/DynamicPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: {
      path: '/home'
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue')
  },
  {
    path: '/services/service-reservation/:id(\\d+)',
    name: 'serviceReservation',
    component: () => import(/* webpackChunkName: "servicesResrvistion" */ '../views/serviceReservation.vue')
  },
  {
    path: '/last-news',
    name: 'Last-News',
    component: () => import(/* webpackChunkName: "lastNews" */ '../views/LastNews.vue'),
  },
  // {
  //   path: '/most-news',
  //   name: 'Most-News',
  //   component: () => import('../views/MostNews.vue'),
  // },
  {
    path: '/last-news/:id(\\d+)',
    name: "News-Artical",
    component: () => import(/* webpackChunkName: "NewsArtical" */ "../views/NewsArtical.vue"),
  },
  // {
  //   path: "/about-eg-embassy",
  //   name: "AboutEmbassy",
  //   component: () => import(/* webpackChunkName: "AboutEmbassy" */ "../views/AboutEgEmbassy.vue"),
  //   params:{sec_id: ''}
  // },
  // {
  //   path: "/about-egypt",
  //   name: "AboutEG",
  //   component: () => import("../views/AboutEgypt.vue")
  // },
  // {
  //   path: "/archive",
  //   name: "Archive",
  //   component: () => import("../views/Archive.vue")
  // },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import(/* webpackChunkName: "ContactUs" */ "../views/ContactUs.vue")
  },
  {
    path: '*',
    component: () => import('../components/misc/404.vue'),
    name: '404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if(store.getters.controlledRoutes.length == 0) {
    getDynamicRoutes(to, next); 
  }else {
    store.commit('changeAppLoading', false)
    next()
  }
})

function getDynamicRoutes(to, next) {
  store.dispatch('loadPages', {})
  .then(res => {
    let data = res.data.results.data;
    // console.log(data)
    data = helpers.sortItems(data);
    // console.log(data)
    data.forEach(el => {
      createAndAppendRoute(el);
      // if (el.sections_count && el.sections_count > 0) {
      //   console.log(el);
      // }
    });
      store.commit('changeAppLoading', false)
      // console.log(router.getRoutes(), to)
      if(router.getRoutes().find(route => route.name == to.name)) {
        next(to.path);
      }else {
        console.log('error')
        next({name: '404'});
      }
        // console.log(this.$router.getRoutes())
      });
  };

function createAndAppendRoute(route) {
  let newRoute = {
    path: `/${route.path}`,
    component: DynamicPage,
    name: `${route.titles.en ? route.titles.en : route.titles}`,
    props: { page_id: route.id },
    meta: {
      page_id: route.id,
      names: route.titles.en ? {...route.titles} : route.titles
    }
  };
  store.commit('addToDynamicRoutes', newRoute);
  router.addRoute(newRoute);
}; 

export default router;
