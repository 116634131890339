
function sortItems(items) {
  let sortedItems;
  sortedItems = items.sort((a, b) => {
    return parseInt(a.ord) - parseInt(b.ord);
  });
  return sortedItems;
};



module.exports = {
  sortItems,
}