<template>
  <div :class="['fixed-menu', dir_ar]">
    <div class="closer" @click="close">
      <i class="fa fa-times"></i>
    </div>
    <div class="row h-100">
      <div class="col-5 col-xl-3">
        <div class="links h-100">
          <ul>
            <li
              v-for="(link, i) in links"
              :key="i"
              :style="`height:calc(100% / ${links.length})`"
            >
              <router-link :to="{ name: link.link }">{{
                link.label
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-7 col-xl-9 service">
        <h2>{{$t('mostReservedServices')}}</h2>

        <div class="row">
          <div
            class="col-6"
            v-for="(card, i) in serviceCards"
            :key="i * card.id + 10"
          >
            <div class="my-5 d-flex align-items-center">
              <img
                :src="card.icon"
                alt="Icon"
                class="pointer"
                width="36"
                @click="serviceReservition(card.id)"
              />
              <p
                class="m-0 mx-3 pointer ser-title"
                @click="serviceReservition(card.id)"
              >
                {{ card.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <img
        src="../../assets/images/al-nisr.png"
        alt="EG Logo"
        class="nisr"
        width="50"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { id: 1, label: this.$t('home'), link: "Home" },
        { id: 2, label: this.$t('allServices'), link: "Services" },
        { id: 3, label: this.$t('About egypt'), link: "AboutEG" },
        { id: 4, label: this.$t('aboutEmbassy'), link: "AboutEmbassy" },
        { id: 5, label: this.$t('latest News'), link: "Last-News" },
        // { id: 6, label: "الاخبار الهامه", link: "Most-News" },
        // { id: 7, label: "الارشيف", link: "Archive" },
        { id: 5, label: this.$t('contactUs'), link: "ContactUs" },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    serviceReservition(id) {
      if (id == this.$route.params.id) {
        return false;
      } else {
        this.$router.push({ name: "serviceReservation", params: { id: id } });
      }
    },
  },
  computed: {
    serviceCards() {
      return this.$store.state.trendingCards;
    },
  },
  watch: {
    $route(newRoute) {
      if (newRoute) {
        this.close();
      }
    },
  },
};
</script>

<style>
</style>