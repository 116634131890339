<template>
  <nav class="nav-container relative-sec">
    <div class="sub-header" v-if="socials">
      <div class="container d-flex align-items-center justify-content-between">
        <div class="links">
          <div class="footer-social-container d-flex">
            <div class="" v-for="social in socials" :key="social.id">
              <button type="button" class="btn-icon-social">
                <a :href="social.link" target="_blank"
                  ><i class="fa" :class="social.icon"
                /></a>
              </button>
            </div>
          </div>
        </div>
        <div class="text">
          {{ $t("terms&Conditions") }}
        </div>
      </div>
    </div>
    <div class="container">
      <div class="appheader-mob" v-if="smallScreens" :class="fl_ar">
        <div class="ind-icon d-flex" :class="fl_ar_rev">
          <LanguageChanger padd="p-0" />

          <span
            v-if="$route.name != 'Home' && $route.name != 'serviceReservation'"
            @click="$router.go(-1)"
            class="pointer shadow rounded p-1"
          >
            <mdicon
              :name="dir_ar == 'dir-rtl' ? 'chevron-left' : 'chevron-right'"
            />
          </span>
          <span
            @click="toggleNavigation"
            class="pointer shadow mx-2 btn-pri p-1"
          >
            <mdicon name="view-headline" />
          </span>
        </div>
        <div class="app-logo mx-2">
          <router-link :to="{ name: 'Home' }">
            <img :src="logo" alt="App Logo" />
            <!-- <p class="desc">
              {{appName}}
            </p> -->
          </router-link>
        </div>
        <div class="overlay" id="overlay" @click="closeExpandNav"></div>
        <div class="expandnav-container" :data-expanded="false" id="expandnav">
          <ul
            :class="['expandnav-items-container', smallScreens ? dir_ar : '']"
          >
            <li
              class="expandnav-item-container"
              v-for="(item, index) in allRoutes"
              :key="index"
            >
              <router-link
                active-class="active"
                exact
                :to="{ name: item.name }"
                class="expandnav-link"
              >
                {{ item.meta ? item.meta.names[lang] : item.name }}
              </router-link>
            </li>

            <button class="btn-nav-mob btn-pri d-none" type="button">
              {{ $t("login") }}
            </button>
          </ul>
        </div>
      </div>

      <div
        class="appheader-container"
        id="appheader-container"
        v-if="largeScreens"
      >
        <div :class="['container', fl_ar]">
          <div :class="['appheader-side', fl_ar_rev]">
            <LanguageChanger :padd="false" class="mx-1" />
            <div
              :class="[
                'fixed-menu-toggler',
                dir_ar == 'dir-rtl' ? 'dir-rtl' : 'dir-ltr',
                'pointer',
              ]"
              @click="toggleFixedMenu"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div></div>
            <div class="appheader-side-buttons">
              <button class="btn-nav d-none" type="button">
                {{ $t("login") }}
              </button>
            </div>
          </div>

          <div class="appheader-links-container">
            <ul class="appheader-items-container" :class="fl_ar_rev">
              <li
                class="appheader-item-container"
                v-for="(item, index) in allRoutes"
                :key="index"
              >
                <router-link
                  :to="{ name: item.name }"
                  active-class="active"
                  exact
                  class="expandnav-link"
                >
                  {{
                    item.meta
                      ? item.meta.names.en
                        ? item.meta.names[lang]
                        : item.name
                      : item.name
                  }}
                </router-link>
              </li>
            </ul>
          </div>

          <router-link :to="{ name: 'Home' }" class="no-link-style">
            <div :class="['app-logo', dir_ar]">
              <img alt="App Logo" :src="logo" />
              <p class="desc">
                {{ appName }}
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <transition name="fade">
      <fixed-menu v-if="fixedMenu" @close="toggleFixedMenu"></fixed-menu>
    </transition>
  </nav>
</template>

<script>
import LanguageChanger from "@/components/navigation/LanguageChanger";
import FixedMenu from "../misc/FixedMenu.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    LanguageChanger,
    FixedMenu,
  },
  data() {
    return {
      fixedMenu: false,
      logo: require("../../assets/images/logo/logo.png"),
      appName: this.$t("appName"),
      appheaderLinks: [
        {
          id: 5,
          label: " الأخبار",
          name: "Last-News",
          meta: {
            names: {
              ar: "الأخبار ",
              en: "Latest News",
              de: "Neuesten Nachrichten",
            },
          },
        },
        {
          id: 8,
          label: "اتصل بنا",
          name: "ContactUs",
          meta: {
            names: {
              ar: "اتصل بنا",
              en: "Contact Us",
              de: "Kontaktiere uns",
            },
          },
        },
      ],
      socials: [],
    };
  },
  watch: {
    fixedMenu() {
      if (this.fixedMenu) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    },
  },
  props: ["closeExpandNav", "openExpandNav"],
  methods: {
    toggleFixedMenu() {
      this.fixedMenu = !this.fixedMenu;
    },
    goToSection(item) {
      // let element = document.getElementById(item.id);
      // element.scrollIntoView({
      //   behavior: "smooth",
      //   block: "center",
      // });
      // if (this.smallScreens == true) {
      //   this.closeNavigation();
      // }
      this.$router.push({ name: item.link });
    },
    toggleNavigation() {
      let nav = document.getElementById("expandnav");
      if (nav) {
        nav.getAttribute("data-expanded") == "true"
          ? this.closeExpandNav()
          : this.openExpandNav();
      }
    },
  },
  computed: {
    ...mapGetters(["controlledRoutes", "getSettings"]),
    allRoutes() {
      return this.controlledRoutes.concat(this.appheaderLinks);
    },
  },
  mounted() {
    if (this.getSettings) {
      this.socials = this.getSettings.social_media_links;
      this.logo = this.getSettings.logo;
      this.appName = this.getSettings.AppName
        ? this.getSettings.AppName
        : this.$t("appName");
    }
    const header = document.getElementById("appheader-container");
    const smHeader = document.querySelector(".appheader-mob");
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 100) {
        if (header) {
          header.classList.add("active");
        }
        if (smHeader) {
          smHeader.classList.add("active");
        }
        document.body.style.paddingTop = header.clientHeight + "px";
      } else {
        if (header) {
          header.classList.remove("active");
        }
        if (smHeader) {
          smHeader.classList.remove("active");
        }
        document.body.style.paddingTop = "0px";
      }
    });
  },
};
</script>
