<template>
  <div :class="['trending-container','my-5', dir_ar]" v-if="!appLoading">
    <div class="container">
      <div class="d-flex align-items-center my-5">
      <h3 class="header-main " >
          {{section.title ? section.title : $t('mostReservedServices') }}
        <span class="header-divider d-inline-block mx-1 mt-1"></span>
      </h3>
        <div :class="['more-btn', dir_ar == 'dir-rtl'?'me-auto':'ms-auto']">
          <button class="btn" @click="viweAll"> {{$t('show all') }}</button>
        </div>
      </div>
      <div class="trending-container">
        <div class="trending-cards row" v-if="servicesStart">
          <TrendingServiceCard
            v-for="(service, index) in homeList"
            :card="service"
            @activeCard="activeCard(index)"
            :key="service.id"
            :elid="index"
            :isActive="truthArray[index] == true"
            :style="{
              backgroundImage: `url(${overlay}),  url(${service.image_url})`,
            }"
            @reservation="serviceReservation(service.id)"
          ></TrendingServiceCard>
        </div>
      </div>
    </div>
  </div>
    <div class="container py-5 my-5" v-else>
  <div class="loader my-5 mx-auto"></div>
  </div>
</template>

<script>
import TrendingServiceCard from "./misc/TrendingServiceCard.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TrendingServiceCard,
  },
  data() {
    return {
      truthArray: [],
      servicesStart: false,
      overlay: require("@/assets/images/overlay.png"),
    };
  },
  mounted() {
    this.getServices({})
      .then(res => {
        setTimeout(() => {
          this.servicesStart = true
        }, 100);
      })
  },  
  props: ['section'],
  methods: {
    ...mapActions(['getServices']),
    activeCard(index) {
      let otherels = [...document.getElementsByClassName("trending-card")];
      otherels.forEach((el) => {
        el.classList.remove("active");
        this.truthArray.push(false);
      });
      let ele = document.getElementById(index);
      ele.classList.toggle("active");
      for (let i = 0; i < this.truthArray.length; i++) {
        if (i == index) {
          this.truthArray[i] = true;
        } else {
          this.truthArray[i] = false;
        }
      }
      this.$forceUpdate();
    },
    serviceReservation(serviceId) {
      this.$router.push({
        name: "serviceReservation",
        params: { id: serviceId },
      });
    },
    viweAll() {
      this.$router.push({ name: "Services" });
    },
  },
  computed: {
    ...mapGetters(["popularTrendingCards", 'services', 'service', 'appLoading']),
    homeList(){
      if(this.services && this.$route.name == 'Home'){
        const list = [this.services[0], this.services[1], this.services[2],this.services[3]];
        return list
      }else{
        return this.services
      }
    }
  },
};
</script>