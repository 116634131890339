<template>
  <div :class="['artical', 'my-5', dir_ar]" v-if="!loading && sectionData">
    <img
      :src="articalImage"
      style="max-width: 600px"
      class="d-none d-md-block"
      alt="Artical Image"
    />
    <h3 class="header-main d-flex align-items-center">
      {{ sectionData.titles[lang] }}
      <span class="header-divider d-inline-block mx-1 mt-1"></span>
    </h3>
    <p
      v-if="sectionData.descriptions[lang]"
      v-html="sectionData.descriptions[lang]"
    ></p>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      articalImage: require("../assets/images/news2.png"),
      loading: true,
      sectionData: null,
    };
  },
  props: ["section"],
  mounted() {
    if (this.section.id) {
      this.loadVSection({
        id: this.section.id,
      }).then((res) => {
        this.sectionData = res.data.results;
        this.articalImage = res.data.results.media_url;
        this.loading = false;
      });
    }
  },
  methods: {
    ...mapActions(["loadVSection"]),
  },
  computed: {
    ...mapGetters(["getVSection"]),
  },
};
</script>